import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Heading3 } from './Heading';
import { IconButton, ButtonStyle } from '../admin_tools/app/styles';
import colors from '../constants/colors';
import EditIcon from '../admin_tools/icons/edit.svg';
import DeleteIcon from '../admin_tools/icons/delete.svg';
import GigType from './GigType';
import { size } from '../constants/responsive';

const Container = styled.div`
  display: flex;

  @media (max-width: ${size.tablet}) {
    flex-direction: column;
  }
`;

const DesktopHeading = styled(Heading3)`
  @media (max-width: ${size.mobileL}) {
      display: none;  
  }
`;

const MobileHeading = styled(Heading3)`
  display: none;
  @media (max-width: ${size.mobileL}) {
      display: block;  
  }
`;

const GigImage = styled.div(props => `
    background-color: ${colors.placeHolder};
    background-image: url(${props.src});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 13em;
    height: 13em;
    margin-right: 2em;
    display: inline-block;
    box-shadow: 2px 2px 4px rgba(0,0,0, 0.3);
    border-radius: 5px;
    flex-shrink: 0;
    
     @media (max-width: ${size.mobileL}) {
        margin 0 0 1em 0 !important;  
        width: 100%;
     }
`);

const Button = styled.button(ButtonStyle);
const DeleteButton = styled(Button)`
    background-color: ${colors.red};
    
    &:hover {
        background-color: ${colors.hoverRed};
    }
`;

const Info = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
`;

const Date = styled.div`
    font-size: 1.3em;
    font-style: italic;
`;

const DateDesktop = styled(Date)`
  @media (max-width: ${size.mobileL}) {
      display: none;  
  }
`;

const DateMobile = styled(Date)`
  display: none;
  margin-bottom: 0.5em;
  @media (max-width: ${size.mobileL}) {
      display: block;  
  }
`;

const EditTools = styled.div`
    position: absolute;
    bottom: 0px;
    right: 0em;
`;

const GigInfo = ({ gig, editMode, onEdit, onDelete }) => {
    const [confirmDelete, setConfirmDelete] = useState(false);

    const confirmDeleteHandler = useCallback(
        () => setConfirmDelete(true),
        [setConfirmDelete]
    );

    const cancelDelete = useCallback(
        () => setConfirmDelete(false),
        [setConfirmDelete]
    );

    return (
        <>
            <Container>
                <MobileHeading>{gig.name}</MobileHeading>
                <DateMobile>{gig.date}</DateMobile>
                <GigImage src={editMode && gig.photo.startsWith('uploads') ? `../${gig.photo}` : gig.photo} />
                <Info>
                    <DesktopHeading>{gig.name}</DesktopHeading>
                    <DateDesktop>
                        {
                            gig.toDate
                                ? (
                                    <>
                                        {`from ${gig.date}`}
                                        <br />
                                        {`to ${gig.toDate}`}
                                    </>
                                )
                                : gig.date
                        }
                    </DateDesktop>
                    <div>{gig.type}</div>
                    <div style={confirmDelete ? { visibility: 'hidden' } : {}}>
                        To read more information or to purchase tickets,
                        {' '}
                        <a href={gig.info}
                           target="_blank"
                           title="Get more information or purchase tickets"
                        >
                            click here
                        </a>.
                    </div>
                </Info>
            </Container>
            {editMode && !confirmDelete && (
                <EditTools>
                    <IconButton type="button"
                                color={colors.darkBlue}
                                hover={colors.logoHoverBlue}
                                icon={EditIcon}
                                onClick={onEdit}
                                title="Edit Gig"
                    >
                        Edit
                    </IconButton>
                    <IconButton type="button"
                                color={colors.red}
                                hover={colors.hoverRed}
                                icon={DeleteIcon}
                                onClick={confirmDeleteHandler}
                                title="Delete Gig"
                    >
                        Delete
                    </IconButton>
                </EditTools>
            )}
            {confirmDelete && (
                <EditTools>
                    <span>Are you sure?</span>
                    <Button type="button" onClick={cancelDelete}>Cancel</Button>
                    <DeleteButton type="button" onClick={onDelete}>Delete</DeleteButton>
                </EditTools>
            )}
        </>
    );
};

GigInfo.defaultProps = {
    editMode: false,
    onEdit: null,
    onDelete: null
};

GigInfo.propTypes = {
    gig: GigType.isRequired,
    editMode: PropTypes.bool,
    onEdit: PropTypes.func,
    onDelete: PropTypes.func
};

export default GigInfo;
