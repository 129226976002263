import styled from 'styled-components';
import { device, size } from '../constants/responsive';

export const PageContainer = styled.div`
    margin: 2.5em 3em 4em 3em;
    max-width: 1024px;
    
    @media ${device.laptopM} {
        margin: 2.5em auto 4em auto;
    }
    
    @media (max-width: ${size.mobileL}) {
        width: auto;
        margin: 2.5em 1em 4em;
    }
`;

export const CenteredImage = styled.div`
    text-align: center;
    margin: 1em 2em;
    
    img {
        max-width: 100%
    }
`;


export const LinearGrid = styled.div(props => `
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    > * {
        width: calc(${1/(props.number || 3) * 100}% - ${props.gap || '1em'});
        ${props.shadow && 'box-shadow: 2px 2px 8px rgba(0,0,0, 0.5)'}
    }
    
    @media (max-width: ${size.mobileL}) {
        flex-direction: column;
        justify-content: center;
        
        > * {
                width: 90%;
                margin-bottom: 2em;
        } 
    }
`);

export const SpacedList = styled.ul`
    margin: 1em 0 0 0;
    
    li {
        margin-bottom: 1em;
    }
    
    @media (max-width: ${size.mobileL}) {
        padding-left: 0;
        list-style: none;
    }
`;

export const FloatImage = styled.img(props => `
    float: ${props.float || 'left'};
    margin: ${props.margin || '0 1em 1em 0'}
`);

export const AlignRight = styled.div`
    text-align: right;
`;

export const ImageBox = styled.div(props => !props.legacySupport
    ? `
    position: relative;
    padding-left: 33%;
    
    @media (max-width: ${size.tablet}) {
        padding-left: 0;
    }
    
    &:before {
            content: '';
            position: absolute;
            background-image: url('${props.src}');
            background-repeat: none;
            background-size: cover;
            background-position: ${props.position || 'top left'};
            top: 0;
            left: 0;
            bottom: 0;
            width: calc(33% - 1em);
            
            @media (max-width: ${size.tablet}) {
                position: relative;
                display: block;
                width: auto;
                margin: 2em 0;
                height: 20em;
            }
    }`
    : '');

export const InputButton = styled.input`
    margin: 0;
    font-size: 1em;
    padding: 0.3em 1.5em;
    cursor: pointer;
    background: black;
    color: white;
    border: none;
    height: 2em;
    outline: none;
    margin-left: 1em;
    
    &:disabled {
        cursor: default;
        opacity: 0.3;
    }
    
    &:hover {
        background: #555;
    }
`;