import { email as emailRoute } from './APIs';

class EmailService {
    /**
     * Sends data to server email API and returns success
     * @param {string} userName
     * @param {string} email
     * @param {string} subject
     * @param {string} message
     * @returns {Promise<boolean>}
     */
    static async sendEmail(userName, email, subject, message) {
        try {
            const response = await fetch(emailRoute, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    userName,
                    email,
                    subject,
                    message
                })
            });

            if (response.ok) {
                const { success } = await response.json();
                return !!success;
            }
        } catch (error) {
            console.error(error);
        }
        return false;
    }
}

export default EmailService;
