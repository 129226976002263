import React from 'react';
import styled from 'styled-components';


const DisplayImage = styled.img( props => `
  width: 100%;
  ${ props.shadow && 'box-shadow: 1px 1px 2px rgba(0,0,0, 0.5)'}
`);

export default DisplayImage;
