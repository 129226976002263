import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import AppRouter from '../router';
import AppStyles from './AppStyles';

const App = () => (
    <Router>
        <AppStyles>
            <Header />
            <AppRouter />
            <Footer />
        </AppStyles>
    </Router>
);

export default App;
