import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const SocialMediaLink = styled.a(props => `
    margin: 0 1em;
    display: inline-block;
    text-decoration: none;
    background-image: url('${props.icon}');
    background-repeat: none;
    background-size: contain;
    width: ${props.size};
    height: ${props.size};
`);

SocialMediaLink.defaultProps = {
    size: '2em',
    target: '_blank'
};

SocialMediaLink.propTypes = {
    href: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    size: PropTypes.string,
    target: PropTypes.string,
    title: PropTypes.string
};

export default SocialMediaLink;
