import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Gig from './Gig';
import GigType from './GigType';

export const GigArray = PropTypes.arrayOf(GigType);

const Container = styled.div`
    ul {
        list-style: none;
        padding: 0;
        margin: 2em 0;
    }
    
    li {
        display: flex;
        margin-bottom: 3em;
        align-items: stretch;
        position: relative;
    }
    
    li:last-child {
        margin-bottom: 0;
    }
`;

const prepGigs = gigs => gigs.map(gig => {
    const update = { ...gig };
    update.date = update.raw;
    update.toDate = update.rawToDate;
    delete update.raw;
    delete update.rawToDate;
    delete update.short;
    delete update.time;
    return update;
});


const GigList = ({ gigs, editMode, onUpdate }) => {
    const handleUpdate = useCallback(async (index, data) => {
        const newGigList = prepGigs(gigs);
        newGigList[index] = data;
        if (typeof onUpdate === 'function') onUpdate(newGigList);
    }, [gigs, onUpdate]);

    const handleDelete = useCallback(index => {
        const newGigList = prepGigs(gigs).filter((_, idx) => idx !== index);
        if (typeof onUpdate === 'function') onUpdate(newGigList);
    }, [gigs, onUpdate]);

    return (
        <Container>
            <ul>
                {gigs.map((gig, index) => (
                    <Gig key={`gig_${gig.name}_${gig.raw}`}
                         editMode={editMode}
                         gig={gig}
                         index={index}
                         onDelete={handleDelete}
                         onUpdate={handleUpdate} />
                ))}
            </ul>
        </Container>
    );
};

GigList.defaultProps = {
    gigs: [],
    editMode: false,
    onUpdate: null
};

GigList.propTypes = {
    gigs: GigArray,
    editMode: PropTypes.bool,
    onUpdate: PropTypes.func
};

export default GigList;
