import { gallery as galleryRoute } from './APIs';

const uploadData = async (method, data, errorMessage) => {
    try {
        const response = await fetch(galleryRoute, {
            method,
            headers: {
                'Content-Type': 'application/json',
                credentials: 'include'
            },
            body: JSON.stringify(data)
        });
        if (response.ok) {
            return true;
        }
    } catch (error) {
        console.error(errorMessage, error);
    }
    return false;
};

class GalleryService {
    static async getGalleryImages() {
        const response = await fetch(galleryRoute);
        if (response.ok) {
            return response.json();
        } else {
            throw new Error('Failed to load images');
        }
    }

    static async addImage(filename, description) {
        return uploadData(
            'POST',
            { filename, description },
            'failed to add image to gallery'
        );
    }

    static async reorderGallery(index1, index2) {
        return uploadData(
            'PUT',
            { index1, index2 },
            'failed to reorder gallery'
        );
    }

    static async deleteImage(index) {
        return uploadData(
            'DELETE',
            { index },
            'failed to delete gallery image'
        );
    }
}

export default GalleryService;
