import PropTypes from 'prop-types';
import FormatDates from '../services/FormatDates';
import PlaceholderIcon from '../admin_tools/icons/image.svg';

const GigType = PropTypes.shape({
    name: PropTypes.string,
    date: PropTypes.string,
    type: PropTypes.string,
    time: PropTypes.string,
    short: PropTypes.string,
    photo: PropTypes.string,
    info: PropTypes.string,
    raw: PropTypes.number
});

export const getSampleGig = (date = Date.now()) => ({
    date: FormatDates.getFormattedDateAndTime(date),
    toDate: '',
    name: 'New Gig',
    type: 'Edit this gig and save to add to site.',
    photo: PlaceholderIcon,
    info: '#',
    time: FormatDates.getTime(date),
    short: FormatDates.getShortDate(date),
    raw: date
});

export default GigType;
