import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Heading1, Heading } from '../components/Heading';
import HeaderImage from '../images/header_2.jpg';
import Logo from '../images/logo_blue.png';
import { device, size } from '../constants/responsive';
import colors from '../constants/colors';

const Container = styled.div`
    text-align: right;
    position: relative;
    
    @media (max-width: ${size.mobileL}) {
      text-align: center;    
    }
`;

const TitleImage = styled.div`
    background-image: url('${HeaderImage}');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left 40%;
    height: 15em;
    padding-top: 1em;
    padding-right: 3em;
    line-height: 2em;
    
    @media (max-width: ${size.mobileL}) {
        background-image: none;
        padding: 0 !important;
        margin-top: 2em;
    }
`;

const LogoImage = styled.img`
    width: 8.7em;
    position: relative;
`;

const HeadingText = styled(Heading)`
    position: relative;
    font-style: italic;
    margin-right: 0.5em;
    margin-top: -0.5em;
    color: ${colors.logoBlue};
    @media (max-width: ${size.mobileL}) {
      margin-right: 0;
    }
`;

const Gradient = styled.div`
    background: linear-gradient(90deg, rgba(255,255,255,0) 80%, rgba(255,255,255,1) 90%);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    @media (max-width: 1500px) {
      background: linear-gradient(90deg, rgba(255,255,255,0) 60%, rgba(255,255,255,1) 90%);
    }
    
    @media (max-width: 900px) {
      background: linear-gradient(90deg, rgba(255,255,255,0) 30%, rgba(255,255,255,1) 90%);
    }
    
    @media (max-width: ${size.mobileL}) {
      display: none;
    }
`;

const PageNav = styled.nav`
    position: relative;
    text-align: left;
    
    ul {
        list-style: none;
        padding-left: 3em;
        
         @media (max-width: ${size.mobileL}) {
            padding-left: 0;
            text-align: center;       
         }
    }
    
    li {
        display: inline-block;
        margin-right: 1.5em;
        
        a {
            font-size: 1.3em;
            text-decoration: none;
            color: ${colors.logoBlue};
        }
    }
    
    li:last-of-type {
        margin-right: 0;
    }
    
     @media ${device.laptopM} {
        width: 1024px;
        margin: 2.5em auto 4em auto;
        
        ul {
            padding-left: 0;
        }
    }
`;


const Header = ({ legacySupport }) => (
    <Container>
        <Gradient />
        <TitleImage>
            <Heading1 margin="0">
                <LogoImage src={Logo} alt="Herne Bay Jazz & Swing Festival" />
            </Heading1>
            <HeadingText margin="0">by the sea</HeadingText>
        </TitleImage>
        {!legacySupport && (
            <PageNav>
                <ul>
                    <li><Link to="./">About</Link></li>
                    <li><Link to="./festival">What's On</Link></li>
                    <li><Link to="./gallery">Gallery</Link></li>
                    <li><Link to="./contact">Contact</Link></li>
                </ul>
            </PageNav>
        )}
    </Container>
);

Header.propTypes = {
    legacySupport: PropTypes.bool
};

Header.defaultProps = {
    legacySupport: false
};

export default Header;
