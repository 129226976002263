import axios from 'axios';
import { upload } from './APIs';

class UploadImage {
    static async uploadImageFile(file) {
        try {
            const formData = new FormData();
            formData.append('photo', file);

            const response = await fetch(upload, {
                method: 'POST',
                headers: {
                    credentials: 'include'
                },
                body: formData
            });

            if (response.ok) {
                const { url } = await response.json();
                return url;
            }
        } catch (error) {
            console.error('Failed to upload image', error);
        }
        return null;
    }

    static async uploadImageFileWithProgress(file, onProgress) {
        try {
            const formData = new FormData();
            formData.append('photo', file);

            const response = await axios.request({
                url: upload,
                method: 'POST',
                headers: {
                    credentials: 'include'
                },
                data: formData,
                onUploadProgress: onProgress
            });
            if (response.status >= 200 && response.status < 300) {
                const { url } = await response.data;
                return url;
            }
        } catch (error) {
            console.error('Failed to upload image', error);
        }
        return null;
    }
}

export default UploadImage;
