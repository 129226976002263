import { whatsOn as whatsOnRoute } from './APIs';
import FormatDates from './FormatDates';

/**
 * @typedef {{donate: string|null, gigs: {name:string,photo:string,type:string,date:string,time:string,short:string,info:string}[]}} GigType
 * @typedef {{donate: string, gigs: GigType[], placeholderDate: string}} AppData
 */

const placeHolderData = {
    donate: null,
    gigs: []
};

class WhatsOnService {
    /**
     * Gets data from server for donate and gigs
     * @returns {Promise<AppData>}
     */
    static async getData(raw= false) {
        const response = await fetch(whatsOnRoute);
        if (response.ok) {
            try {
                const data = await response.json();
                if (!raw) {
                    data.gigs = data.gigs.map(gig => ({
                        ...gig,
                        date: gig.toDate
                            ? FormatDates.getLongDate(gig.date)
                            : FormatDates.getFormattedDateAndTime(gig.date),
                        toDate: gig.toDate
                            ? FormatDates.getLongDate(gig.toDate)
                            : '',
                        time: FormatDates.getTime(gig.date),
                        short: FormatDates.getShortDate(gig.date),
                        raw: gig.date,
                        rawToDate: gig.toDate
                    }));
                }
                return data;
            } catch (error) {
                console.error('Failed to parse JSON', error);
            }
        } else {
            console.error('Failed to download what\'s on data');
        }

        return placeHolderData;
    }

    /**
     * Upload Data for updating server-side app store, returns success boolean
     * @throws
     * @param {AppData} newData
     * @returns {Promise<boolean>}
     */
    static async updateData(newData) {
        try {
            const response = await fetch(whatsOnRoute, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(newData)
            });
            if (response.ok) {
                const { success } = await response.json();
                return success;
            }
        } catch (error) {
            console.error('Failed to update data', error);
        }
        return false;
    }
}

export default WhatsOnService;
