import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from 'react-router-dom';
import Home from '../pages/Home';
import Festival from '../pages/Festival';
import Contact from '../pages/Contact';
import Gallery from '../pages/Gallery'
import { ProductionRoot } from '../services/APIs';

const AppRouter = () => (
    <Switch>
        <Route path={`${ProductionRoot}/festival`}>
            <Festival />
        </Route>
        <Route path={`${ProductionRoot}/gallery`}>
            <Gallery />
        </Route>
        <Route path={`${ProductionRoot}/contact`}>
            <Contact />
        </Route>
        <Route path={`${ProductionRoot}/`}>
            <Home />
        </Route>
    </Switch>
);

export default AppRouter;
