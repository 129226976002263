import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import MailChimpForm from '../components/MailChimpForm';
import SocialMediaLink from '../components/SocialMediaLink';
import Twitter from '../images/twitter.svg';
import FaceBook from '../images/facebook.svg';
import Instagram from '../images/instagram.svg';
import SponsorLogos from '../components/SponsorsLogos';
import { size } from '../constants/responsive';

const Container = styled.div`
    margin: 0;
    padding: 1.5em 0 0 0;
    background: lightgrey;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
`;

const LegacyContainer = styled.div`
    margin: 0;
    background: lightgrey;
    text-align: center;
    padding: 1em 0 0;
    overflow: hidden;
`;

const IconGrid = styled.div`
    display: inline-flex;
    padding: 0;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    
    img {
      margin: 1em 0;
    }
    
    @media (max-width: ${size.mobileL}) {
        flex-direction: column;
        padding: 0;
        
         img {
            margin: 0;
         }
    }
`;


const Left = styled.div`
    padding-top: 1.6em;
    margin-right: 3em;
    
    a:first-of-type {
        margin-left: 0;
    }
    
    @media (max-width: ${size.mobileL}) {
      margin: 0 0 1.5em;
      padding: 0;
      
      a:last-of-type {
        margin-right: 0;
      }
    }
`;

const Right = styled.div`
    width: 30em;
    
    @media (max-width: ${size.mobileL}) {
        width: calc(100% - 2em);
        margin: 0;
        text-align: center !important;
    }
`;

const Sponsors = styled.div`
    margin: 1em 0;
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 1em;
    
    img {
        height: 4em;   
        margin-right: 2em;
        
        @media (max-width: 480px) {
          height: auto;
          width: auto;
          max-width: 100%;
          max-height: 8em;
          margin-right: 0;
          margin-bottom: 1.5em;
        }
    }
    
    img:last-of-type {
        margin-right: 0;
    }
    
    @media (max-width: ${size.mobileL}) {
        margin: 2em 1em 1em;
        text-align: center !important;
    }
`;

const SponsorsTitle = styled.div`
    font-size: 1.1em;
    margin: 0 1em;
    width: 100%;
    
    @media (max-width: ${size.mobileL}) {
      margin: 0 0 1em 0;
    }
`;

const CopyrightStrip = styled.div`
    background: grey;
    padding: 0.5em 2em;
    text-align: center;
    width: 100%;
    
    @media (max-width: ${size.mobileL}) {
      padding: 1em 2em;
      line-height: 1.6em;
      box-sizing: border-box;
    }
`;

const LegacyList = styled.ul`
  padding: 0;
  list-style: none;
  
  a {
    display: inline-block;
    margin-right: 1em;
  }
  
  a:last-of-type {
    margin-right: 0;
  }
`;

const LegacyLogos = styled.div`
  margin: 1em 0;
  img {
      width: 6em;
      display: inline-block;
      margin: 0.5em;
      vertical-align: middle;
  }
`;

const LegacyMailForm = styled.div`
  display: inline-block;
  max-width: 600px;
  margin: 1.5em 0;
`;

const Footer = ({ legacySupport }) => legacySupport
    ? (
        <LegacyContainer>
            <LegacyList>
                <SocialMediaLink icon={Twitter} href="https://twitter.com/HerneBayJazz" title="Twitter" size={'2em'} />
                <SocialMediaLink icon={FaceBook} href="https://www.facebook.com/hernebayjazzandswingfest" title="FaceBook" size={'2em'} />
                <SocialMediaLink icon={Instagram} href="https://www.instagram.com/hernebayjazz" title="Instagram" size={'2em'} />
            </LegacyList>
            <LegacyMailForm>
                <MailChimpForm />
            </LegacyMailForm>
            <LegacyLogos>
                <p>Sponsored by</p>
                <SponsorLogos />
            </LegacyLogos>
            <CopyrightStrip>
                © Copyright Broad Reach Records. All&nbsp;Rights&nbsp;Reserved.
            </CopyrightStrip>
        </LegacyContainer>
    )
    : (
        <Container>
            <IconGrid>
                <Left>
                    <SocialMediaLink icon={Twitter} href="https://twitter.com/HerneBayJazz" title="Twitter" />
                    <SocialMediaLink icon={FaceBook} href="https://www.facebook.com/hernebayjazzandswingfest" title="FaceBook" />
                    <SocialMediaLink icon={Instagram} href="https://www.instagram.com/hernebayjazz" title="Instagram" />
                </Left>
                <Right>
                    <MailChimpForm />
                </Right>
            </IconGrid>
            <Sponsors>
                <SponsorsTitle>Sponsored by</SponsorsTitle>
                <IconGrid>
                    <SponsorLogos />
                </IconGrid>
            </Sponsors>
            <CopyrightStrip>
                © Copyright Broad Reach Records. All&nbsp;Rights&nbsp;Reserved.
            </CopyrightStrip>
        </Container>
    );

Footer.propTypes = {
    legacySupport: PropTypes.bool
};

Footer.defaultProps = {
    legacySupport: false
};

export default Footer;