import moment from 'moment';

const longDate = 'ddd, MMMM Do YYYY';
const shortDate = 'MMM Do YYYY';
const time = 'LT';
const full = 'dddd, MMMM Do YYYY, LT';

const format = (timestamp, fmt) => moment(
    typeof timestamp === 'string'
        ? Date.parse(timestamp)
        : timestamp
).format(fmt);

class FormatDates {
    static getLongDate(timestamp) {
        return format(timestamp, longDate);
    }

    static getShortDate(timestamp) {
        return format(timestamp, shortDate);
    }

    static getTime(timestamp) {
        return format(timestamp, time);
    }

    static getFormattedDateAndTime(timestamp) {
        return format(timestamp, full);
    }
}


export default FormatDates;
