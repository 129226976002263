import styled, { css } from 'styled-components';

export const ButtonStyle = css`
    border: none;
    background: black;
    color: white;
    border-radius: 5px;
    box-shadow: 2px 2px 4px rgba(255,255,255,0.5) inset;
    font-size: 1em;
    margin: 0 0 0 1em;
    padding: 0.5em 1em;
    transition: background 0.3s;
    cursor: pointer;
    
    &:disabled {
        cursor: default;
        opacity: 0.5;
    }
    
    &:hover {
        background-color: #555;
        color: white;
    }
`;

export const IconButton = styled.button( props => `
    border: none;
    background-color: ${props.color};
    background-image: url(${props.icon});
    background-repeat: no-repeat;
    background-size: 1.5em;
    background-position: center;
    border-radius: 5px;
    box-shadow: 2px 2px 4px rgba(255,255,255,0.5) inset;
    width: 2em;
    height: 2em;
    overflow: hidden;
    color: transparent;
    margin: ${props.margin || '0 0 0 1em'};
    font-size: ${props.size || '1em'};
    transition: background 0.3s;
    cursor: pointer;
    
    &:disabled {
        cursor: default;
        opacity: 0.5;
    }
    
    &:hover {
        background-color: ${props.hover};
    }
`);

export const PageContainer = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const PageDetails = styled.div`
    width: 60vw;
    min-width: 20em;
`;
