import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import GigType from './GigType';
import GigInfo from './GigInfo';
import GigEdit from './GigEdit';

const Gig = ({ gig, index, editMode, onUpdate, onDelete }) => {
    const [editing, setEditing] = useState(false);
    const onEdit = useCallback(() => setEditing(true), [setEditing]);
    const deleteHandler = useCallback(() => {
        if (onDelete) onDelete(index);
    }, [onDelete]);
    const cancelEditHandler = useCallback(() => setEditing(false), [setEditing]);
    const updateHandler = useCallback(update => onUpdate(index, update), [onUpdate]);
    useEffect(() => {
        if (editMode) setEditing(false);
    }, [gig, index]);

    return (
        <li>
            {!editing && <GigInfo gig={gig} editMode={editMode} onEdit={onEdit} onDelete={deleteHandler} />}
            {editing && <GigEdit gig={gig} onCancel={cancelEditHandler} onUpdate={updateHandler}  />}
        </li>
    );
};

Gig.defaultProps = {
    editMode: false,
    onUpdate: null,
    onDelete: null
};

Gig.propTypes = {
    gig: GigType.isRequired,
    index: PropTypes.number.isRequired,
    editMode: PropTypes.bool,
    onUpdate: PropTypes.func,
    onDelete: PropTypes.func
};

export default Gig;

