import React, { useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import GigType from './GigType';
import { IconButton } from '../admin_tools/app/styles';
import CancelIcon from '../admin_tools/icons/cancel.svg';
import ConfirmIcon from '../admin_tools/icons/confirm.svg';
import colors from '../constants/colors';
import Progress from './Progess';
import UploadImageService from '../services/UploadImage';

const Container = styled.div`
    width: 100%;
    label {
        text-transform: capitalize;
        font-weight: bold;
    }
    
    > p, > div {
        margin-left: 1em;
    }
`;

const Buttons = styled.div`
    text-align: right;
    margin: 0.5em 1em 0 0;
`;

const Input = styled.input`
    font-size: 1em;
    margin: 0.5em 0 0 0;
    padding: 0.5em 1em;
    width: 100%;
`;

const Text = styled.textarea`
    font-size: 1em;
    padding: 0.5em 1em;
    margin: 0.5em 0 0 0;
    width: 100%;
    height: 8em;
`;

const UploadImage = styled.img`
    width: 8em;
    background: ${colors.darkGrey};
    margin: 0.5em 2em -1em 0;
    display: inline-block;
`;

const ErrorP = styled.p`color: red`;

const getDateFormat = raw => {
    if (raw) {
        const d = new Date(raw);
        try {
            return `${d.toISOString().split('T')[0]}T${d.toLocaleTimeString().match(/^(.+):\d\d$/)[1]}`;
        } catch (error) {
            console.error(error);
        }
    }
    return '';
};

const GigEdit = ({ gig, onUpdate, onCancel }) => {
    const photoInput = useRef(null);
    const [updatingImage, setUpdatingImage] = useState(false);
    const [image, setImage] = useState(gig.photo);
    const [error, setError] = useState(null);
    const [values, setValues] = useState({
        ...gig,
        date: (() => getDateFormat(gig.raw))(),
        toDate: (() => getDateFormat(gig.rawToDate))()
    });

    const saveUpdate = useCallback(async () => {
        const update = { ...values };
        delete update.short;
        delete update.time;
        delete update.raw;
        delete update.rawToDate;
        update.date = Date.parse(update.date);
        update.toDate = update.toDate ? Date.parse(update.toDate) : '';
        if (Number.isNaN(update.date) || Number.isNaN(update.toDate)) {
            alert('Invalid Date!');
        } else {
            if (photoInput.current && photoInput.current.files && photoInput.current.files[0]) {
                const imageURL = await UploadImageService.uploadImageFile(photoInput.current.files[0]);
                if (!imageURL) {
                    alert("Failed to upload image, try again with another update");
                } else {
                    update.photo = imageURL;
                }
            }
            onUpdate(update);
        }
    }, [values]);

    const updateValues = useCallback(
        ({ target }) => setValues({ ...values, [target.name]: target.value }),
        [values, setValues]
    );

    const previewImage = useCallback((event) => {
        const { current: fileInput } = photoInput;
        if (fileInput && fileInput.files && fileInput.files[0]) {
            if (fileInput.files[0].size/1024/1024 >= 5) {
                setError('Image file is too large. Please use a smaller file. Limit is 5MB');
                fileInput.value = null;
            } else {
                setUpdatingImage(true);
                setImage(URL.createObjectURL(fileInput.files[0]));
                setError(null);
            }
        }
    }, [setImage, photoInput]);

    const finishedUpdating = useCallback(() => setUpdatingImage(false), [setUpdatingImage]);

    return (
        <Container>
            <h3>Edit Gig</h3>

            {Object.keys(values)
                .filter(key => !['time', 'short', 'photo', 'raw', 'rawToDate'].includes(key))
                .map(key => (
                    <p key={`edit_${key}`}>
                        <label>{key === 'info' ? 'Key (ticket URL)' : key}:</label><br/>
                        {
                            key === 'type'
                                ? <Text name={key} onChange={updateValues} value={values[key]} />
                                : <Input type={key.match(/date/i) ? 'datetime-local' : 'text'}
                                         name={key}
                                         onChange={updateValues}
                                         value={values[key]} />
                        }
                    </p>
                ))}

                <div>
                    <label>Upload Image:</label><br />
                    <UploadImage src={image.startsWith('uploads') ? `../${image}` : image} onLoad={finishedUpdating} />
                    {updatingImage && <Progress size="1.5em" margin="0 1em 0 0" />}
                    <input type="file" onChange={previewImage} ref={photoInput} accept="image/png, image/jpeg" />
                    {error && <ErrorP>{error}</ErrorP>}
                </div>

            <Buttons>
                <IconButton icon={CancelIcon} color={colors.red} hover={colors.hoverRed} onClick={onCancel} />
                <IconButton icon={ConfirmIcon} color={colors.green} hover={colors.hoverGreen} onClick={saveUpdate} />
            </Buttons>
        </Container>
    );
};

GigEdit.defaultProps = {

};

GigEdit.propTypes = {
    gig: GigType.isRequired,
    onUpdate: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
};

export default GigEdit;
