import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import PhotoGallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import { PageContainer } from './styles';
import Progress from '../components/Progess';
import { Heading2 } from '../components/Heading';
import GalleryService from '../services/GalleryService';

const LoadingDiv = styled.div`
    margin: 6em 0;
    text-align: center;
`;

const mapSize = images => images.map(image => {
    return new Promise((resolve) => {
        const img = new Image();
        img.onload = function() {
            resolve({
                ...image,
                caption: image.alt,
                width: this.width,
                height: this.height
            });
        };
        img.onerror = () => {
            resolve(null);
        };
        img.src = image.src;
    });
});

const Gallery = () => {
    const [images, setImages] = useState(null);
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = useCallback((event, { photo, index }) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    }, []);

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    useEffect(() => {
        (() => {
            (async () => {
                const data = await GalleryService.getGalleryImages();
                if (Array.isArray(data)) {
                    const sizedImages = await Promise.all(mapSize(data));
                    setImages(sizedImages.filter(img => img !== null));
                }
            })();
        })();
    }, []);

    return (
        <PageContainer>
            <Heading2>Gallery</Heading2>
            {!images && (
                <LoadingDiv>
                    <p>Loading Data...</p>
                    <Progress />
                </LoadingDiv>
            )}
            {images && (
                <>
                    <PhotoGallery photos={images} onClick={openLightbox} />
                    <ModalGateway>
                        {viewerIsOpen ? (
                            <Modal onClose={closeLightbox}>
                                <Carousel
                                    currentIndex={currentImage}
                                    views={images}
                                />
                            </Modal>
                        ) : null}
                    </ModalGateway>
                </>
            )}
        </PageContainer>
    );
};

export default Gallery;
