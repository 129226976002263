import pkg from '../../package';

export const ProductionRoot = (
    process.env.NODE_ENV !== 'development'
        && pkg.build_specs
        && pkg.build_specs.productionRoute
) || '';

export const API_ROOT = `${ProductionRoot}/api/v1.0`;
export const email = `${API_ROOT}/send_email.php`;
export const whatsOn = `${API_ROOT}/whats_on.php`;
export const user = `${API_ROOT}/user.php`;
export const upload = `${API_ROOT}/upload_image.php`;
export const gallery = `${API_ROOT}/gallery.php`;