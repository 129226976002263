import React from 'react';
import styled from 'styled-components';

const fontFamily = '"Arial Black", Gadget, sans-serif';


export const Heading1 = styled.h1(props => (`
    font-family: ${fontFamily};
    font-size: ${props.size || '1.8em'};
    color: ${props.color | 'black'};
    font-style: ${props.style || 'normal'};
    font-weight: ${props.weight || 'normal'};
    text-decoration: ${props.decoration || 'none'};
    margin: ${props.margin || '0.5em 0'}
`));

export const Heading2 = styled.h2(props => (`
    font-family: ${fontFamily};
    font-size: ${props.size || '1.6em'};
    color: ${props.color | 'black'};
    font-style: ${props.style || 'normal'};
    font-weight: ${props.weight || 'normal'};
    text-decoration: ${props.decoration || 'none'};
    margin: ${props.margin || '0.5em 0'}
`));

export const Heading3 = styled.h3(props => (`
    font-family: ${fontFamily};
    font-size: ${props.size || '1.4em'};
    color: ${props.color | 'black'};
    font-style: ${props.style || 'normal'};
    font-weight: ${props.weight || 'normal'};
    text-decoration: ${props.decoration || 'none'};
    margin: ${props.margin || '0.5em 0'}
`));

export const Heading = styled.div(props => (`
    font-family: ${fontFamily};
    font-size: ${props.size || '1.4em'};
    color: ${props.color | 'black'};
    font-style: ${props.style || 'normal'};
    font-weight: ${props.weight || 'normal'};    
    text-decoration: ${props.decoration || 'none'};
    margin: ${props.margin || '0.5em 0'}
`));
