import isCompatible, { name, ios } from './helpers/isCompatible';

if (isCompatible) {
    require('regenerator-runtime/runtime');
    const React = require('react');
    const ReactDom = require('react-dom');
    const App = require('./app').default;
    const container = document.createElement('div');
    document.body.appendChild(container);
    ReactDom.render(<App/>, container);
} else {
    console.log('Browser is not compatible -- activating fallback');
    const iframe = document.createElement('iframe');
    iframe.sandbox = 'allow-forms';
    iframe.src = '/fallback/fallback.html';
    if (name === 'Safari' && ios) {
        iframe.className = 'fallbackIos';
        const container = document.createElement('div');
        container.className = 'iosContainer';
        container.appendChild(iframe);
        document.body.appendChild(container);
    } else {
        iframe.className = 'fallback';
        document.body.appendChild(iframe);
    }
}