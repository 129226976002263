import styled from 'styled-components';
import { size } from '../constants/responsive';

// For use in a flex-box
export const MobileOrderSwap = styled.div`
  @media (max-width: ${size.mobileL}) {
      display: flex;
      flex-direction: column-reverse;
  }
`;

export const HideOnMobile = styled.div`
  @media (max-width: ${size.mobileL}) {
      display: none;
  }
`;