import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { PageContainer, LinearGrid, SpacedList, ImageBox } from './styles';
import { Heading2, Heading3 } from '../components/Heading';
import CollageImage2017 from '../images/about_collage_2017.jpg';
import CollageImage2018 from '../images/about_collage_2018.jpg';
import CollageImage2019 from '../images/about_collage_2019.jpg';
import CollageImage2021 from '../images/about_collage_2021.jpg';
import CollageImage2022 from '../images/about_collage_2022.jpg';
import CollageImage2023 from '../images/about_collage_2023.jpg';
import Brochure2024 from '../images/2024_brochure.pdf';
import SunsetImage from '../images/about_sunset.jpg'
import { size } from '../constants/responsive';
import WhatsOnService from '../services/WhatsOn';
import { DonateButton } from '../components/Buttons';
import DisplayImage from '../components/DisplayImage';
import FooterPic from '../images/header.jpg';

const SpacedLinearGrid = styled(LinearGrid)`
  margin-top: 4em;
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
  
  @media (max-width: ${size.mobileL}) {
    margin-top: 2em;
  }
`;

const SpacedHeading = styled(Heading3)`
  margin-top: 3em;
  @media (max-width: ${size.mobileL}) {
    margin-top: 1em;
  }  
`;

const LegacySupportBox = styled.div`
  border: 1px solid red;
  padding: 1em;
  h2 { margin-top: 0 }
  p { margin-bottom: 0 }
`;

const Home = ({ legacySupport, title, message }) => {
    const [ donateLink, setDonateLink] = useState(null);

    useEffect(() => {
        (() => {
            (async () => {
                const data = await WhatsOnService.getData();
                if (typeof data === 'object') {
                    setDonateLink(data.donate);
                }
            })();
        })();
    }, []);

    return (
        <PageContainer>
            {legacySupport && (
                <LegacySupportBox>
                    <h2>{title}</h2>
                    <p dangerouslySetInnerHTML={{ __html: message }} />
                </LegacySupportBox>
            )}
            {donateLink && (
                <DonateButton href={donateLink} target="_blank" title="Make a donation to the festival">
                    Donate
                </DonateButton>
            )}
            <Heading2>Herne Bay Jazz Festival</Heading2>
            <p>
                Since 2017, the non-profit Herne Bay Jazz Festival has celebrated the best of live jazz from across the
                genre on an annual basis with headliners including some of the top names in the industry. With a large free
                stage program, we provide many opportunities to enjoy and explore this music without the usual expense. Our
                stunning coastal setting is the perfect location for top-calibre performances for everyone, from family events
                to big names, enjoyed by our community, visitors from around the UK and abroad.
            </p>

            {legacySupport && (
                <div style={{ height: '400px', backgroundImage: `url(${SunsetImage})`, backgroundSize: 'cover', backgroundPosition: 'center' }} />
            )}

            <ImageBox src={SunsetImage} position="center left" legacySupport={legacySupport}>
                <p>
                    With a strong emphasis on exploring this genre of music without the barriers of cost or travel, our
                    program includes new and established performers across a wide range of jazz styles, with free workshops
                    for children and adults to explore their own creativity, offering our audiences the broadest selection possible,
                    with an emphasis on inclusivity.
                </p>

                <p>
                    Founded by artistic director Kai Hoffman, Herne Bay Jazz Festival CIC became a registered non-profit
                    community interest company in February, 2024. As a CIC, we are currently expanding our year-round programs
                    which will include workshops and performances over the course of the year as well as our August events.
                </p>
                <p>
                    Our partners include Ronnie Scott's Club, The King’s Hall, Herne Bay Pier Trust, Beach Creative Arts Centre,
                    Cabin FM, The Pier Ceylon, The Ship Inn, Pete’s Photography and Baytastic Events. The festival has been
                    put on with assistance from major sponsor Arts Council England Lottery Fund, as well as Kent County Council,
                    the Herne Bay Festival Charity, Canterbury City Council, Vivien of Holloway, Community Ad Magazine, Music Bay,
                    Goody Ales, She Rose, Mortimer’s Restaurant, Pizza on the Pier, Councillor Rachel Carnac and more.
                </p>
            </ImageBox>

            <SpacedLinearGrid shadow>
                <img src={CollageImage2023} alt="Collage of performers from 2023" />
                <img src={CollageImage2022} alt="Collage of performers from 2022" />
                <img src={CollageImage2021} alt="Collage of performers from 2021" />
                <img src={CollageImage2019} alt="Collage of performers from 2019" />
                <img src={CollageImage2018} alt="Collage of performers from 2018" />
                <img src={CollageImage2017} alt="Collage of performers from 2017" />
            </SpacedLinearGrid>

            <SpacedHeading>Who’s Who</SpacedHeading>

            <SpacedList style={{ marginBottom: '3em' }}>
                <li style={{ marginBottom: '1em'}}>
                    <strong>2024:</strong>
                    <hr/>
                    Ronnie Scott’s All Stars / Ray Gelato & the Giants / Snowboy & the Latin Section / Elina Duni & Rob Luft
                    / Emma Rawicz / Leon Foster Thomas / Steve Waterman / Jo Fooks / The Big Smoke Family / Ginger Bennett /
                    Kent Youth Jazz Orchestra / Samba Ya Wantsum / Kai’s Kats  / Frances Knight / Eriko Ishihara / Geoff Mason /
                    Dulcie May Moreno / Big Sound Collective / Steve Nathan Big Band / Nina Clark / Stephen Bridgland / Katie Bradley
                    / The Native Oysters / Mike Austin / Sarah-Jane Hassell / Jo Doolan / Ian East / Maiuko / Adrian Hackford
                    / Ellie Laine / Roger Lewin / Andy MacLean / DJ Reverend Boogie / Nev Willis / Tim Jenkins And More
                    <a href={Brochure2024} title="2024 brochure" target="_blank" style={{ margin: '0.5em 0 1em', display: 'block' }} >View brochure for 2024 festival.</a>
                </li>
                <li style={{ marginBottom: '1em'}}>
                    <strong>2023:</strong>
                    <hr/>
                    Clare Teal 4 with Jason Rebello / Jim Mullen / Dennis Rollins / Lokkhi Terra / Kishon Khan / The Coalminers
                    / Geoff Mason / Dave Robinson / Kent Youth Jazz Orchestra / Kai’s Kats / Paul Speare / Shireen Francis
                    / Richard Rozze / Kerri Layton / Kai Hoffman / Ellie Laine / Sarah-Jane Hassell / Andy MacClean / DJ
                    Reverend Boogie
                </li>
                <li style={{ marginBottom: '1em'}}>
                    <strong>2022:</strong>
                    <hr/>
                    2022- Ronnie Scott's Blues Explosion / Elaine Delmar / Tony Kofi / Sarabanda / Kai Hoffman / Daniel Cano / Roan Kearsey-Lawson /
                    Kent Youth Jazz Orchestra / Paul Booth / Giorgio Serci / Adriana Vasques / Roger Lewin / Geoff Mason / Dave Robinson /
                    Richard Rozze / Jo Doolan / Sarah Weller / Simon Golding and more
                </li>
                <li style={{ marginBottom: '1em'}}>
                    <strong>2021:</strong><br/>
                    <hr/>
                    Tommy Blaize / Ian Shaw / Louise Cookman with Peter Long Orchestra / Enrico Tomasso / Rachel Cohen /
                    Kai Hoffman / John Etheridge / Eliane Correa / Geoff Mason / Juliet Kelly / KYJO / Java Jam /
                    The Native Oysters / The Daisy Bowlers and more
                    <hr/>
                </li>

                <li style={{ marginBottom: '1em'}}>
                    <strong>2020:</strong><br />
                    <hr/>
                    No Festival due to Covid-19
                    <hr/>
                </li>
                <li>
                    <strong>2019:</strong><br />
                    <hr/>
                    Ian Shaw / Mark Nightingale / Andy Panayi / Carl Orr / The Fallen Heroes / Kai Hoffman
                    <hr/>
                </li>
                <li>
                    <strong>2018:</strong><br />
                    <hr/>
                    Tina May / Stan Sulzmann / Nigel Price / Dom Pipkin and the Ikos / Roan Kearsey-Lawson / Kai Hoffman /
                    Herne Bay Jazz Choir
                    <hr/>
                </li>
                <li>
                    <strong>2017:</strong><br />
                    <hr/>
                    Digby Fairweather / Jim Mullen / Paul Booth / Kai Hoffman / Roan Kearsey-Lawson / Herne Bay Jazz Choir
                    <hr/>
                </li>
            </SpacedList>

            <DisplayImage src={FooterPic} alt="View of the pier" shadow/>

        </PageContainer>
    );
};

Home.propTypes = {
    legacySupport: PropTypes.bool,
    title: PropTypes.string,
    message: PropTypes.string
};

Home.defaultProps = {
    legacySupport: false,
    title: 'Your browser is not supported by this site.',
    message: 'Please visit again using a new version of Chrome, Firefox, Safari, Opera, or Edge for the full experience.'
};

export default Home;
