import styled from 'styled-components';
import preloaderIcon from '../images/preloader.gif';

const Progress = styled.div( props => `
    display: inline-block;
    overflow: hidden;
    color: transparent;
    vertical-align: middle;
    margin: ${props.margin || '0'};
    width: ${props.size || '2em'};
    height: ${props.size || '2em'};
    background-image: url(${preloaderIcon});
    background-repeat: no-repeat;
    background-size: contain;
`);

export default Progress;
