const colors = {
    white: '#FFF',
    black: '#000',
    offWhite: '#EFEFEF',
    logoBlue: '#4fa0d5',
    logoHoverBlue: '#625dd5',
    error: '#b30011',
    placeHolder: '#ababab',
    lightGrey: '#efefef',
    darkGrey: '#b3b3b3',
    green: '#1aaf1e',
    red: '#a21016',
    darkBlue: '#122e5d',
    hoverRed: '#cf1017',
    hoverGreen: '#44bc4b',
    lightBlue: '#b9c8ff'
};

export default colors;
