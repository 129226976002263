import styled from 'styled-components';
import colors from '../constants/colors';
import { size } from '../constants/responsive';

export const DonateButton = styled.a`
    float: right;
    font-size: 1.3em;
    border-radius: 8px;
    background-color: ${colors.logoBlue};
    padding: 0.5em 1em;
    color: white !important;
    margin-top: -2em;
    border: none;
    
    &:hover {
        background-color: ${colors.logoHoverBlue}
    }
    
    @media (max-width: ${size.mobileL}) {
      display: block;
      margin: 1em auto;
      position: relative;
      float: none;
    }
`;

