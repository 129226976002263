import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { PageContainer } from './styles';
import Progress from '../components/Progess';
import { Heading2, Heading } from '../components/Heading';
import WhatsOnService from '../services/WhatsOn';
import GigList from '../components/GigList';
import { NewsLetterHREF } from '../components/MailChimpForm';
import { size } from '../constants/responsive';
import { DonateButton } from '../components/Buttons';
import KingsHall from '../images/kings_hall.jpg';
import DisplayImage from '../components/DisplayImage';
import { MobileOrderSwap } from '../components/Mobile';

const LoadingDiv = styled.div`
    margin: 6em 0;
    text-align: center;
`;

const PlaceHolderMessage = styled.div`
    margin: 3.5em 0 8em 3em;
    
    @media (max-width: ${size.mobileL}) {
      margin: 1.5em 0;
    }
`;

const Festival = () => {
    const [donateLink, setDonateLink] = useState(null);
    const [gigs, setGigs] = useState(null);
    const [placeHolderDate, setPlaceHolderDate] = useState('');

    useEffect(() => {
        (() => {
            (async () => {
                const data = await WhatsOnService.getData();
                if (typeof data === 'object') {
                    setDonateLink(data.donate);
                    setGigs(data.gigs);
                    setPlaceHolderDate(data.placeholderDate);
                }
            })();
        })();
    }, []);

    return (
        <PageContainer>
            <Heading2>What's On</Heading2>
            {!gigs && (
                <LoadingDiv>
                    <p>Loading Data...</p>
                    <Progress />
                </LoadingDiv>
            )}
            <MobileOrderSwap>
                {donateLink && (
                    <DonateButton href={donateLink} target="_blank" title="Make a donation to the festival">
                        Donate
                    </DonateButton>
                )}
                {gigs && gigs.length > 0 && <GigList gigs={gigs} />}
                {gigs && gigs.length === 0 && (
                    <PlaceHolderMessage>
                        <p>Herne Bay Jazz & Swing Festival {placeHolderDate}</p>
                        <p>Stay Tuned for Announcement of Acts & Shows</p>
                        <p>
                            <a href={NewsLetterHREF} title="Sign up for the newsletter" target="_blank">Sign Up Here</a>,
                            So You Don't Miss Out!
                        </p>
                    </PlaceHolderMessage>
                )}
            </MobileOrderSwap>
            <DisplayImage src={KingsHall} alt="View of kings hall" shadow />
        </PageContainer>
    );
};

export default Festival;
