const BrowserDtector = require('browser-dtector');

let isCompatible = true;
export let name = '';
export let version = '';
export let ios = false;

try {
    if (window && window.navigator && window.navigator.userAgent) {
        const browserDtector = new BrowserDtector(window.navigator.userAgent);
        const browser = browserDtector.getBrowserInfo();
        name = browser.name;
        ios =  browser.platform === 'IPhone';
        const parseInt = Number.parseInt || window.parseInt;
        version = parseInt(browser.version);

        if (browser.name.match(/(Safari|Chrome|Firefox|Edge|Internet Explorer|Opera|Samsung)/i)) {
            isCompatible = !!browser && (
                browser.name === 'Safari' && version >= 10
                || browser.name === 'Google Chrome' && version >= 50
                || browser.name === 'Mozilla Firefox' && version >= 39
                || browser.name === 'Microsoft Edge' && version >= 15
                || browser.name === 'Opera' && version >= 36
                || browser.name === 'Samsung Browser' && version >= 1
                || false
            );
        }
    }
} catch (error) {
    console.log('Cannot probe browser type', error);
}

export default isCompatible;
