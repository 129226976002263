import React from 'react';
import styled from 'styled-components';
import { size } from '../constants/responsive';

export const NewsLetterHREF = 'https://kaihoffman.us2.list-manage.com/subscribe/post?u=ec5fe633de77e4de48587fbf1&amp;id=d805460756';

const Form = styled.form`
    margin: 0;
`;

const Label = styled.label`
    display: block;
    font-weight: bold;
`;

const AddressInput = styled.input`
    margin: 0;
    font-size: 1em;
    padding: 0.3em 0.5em;
    height: 2em;
    flex-grow: 1;
    flex-shrink: 0;
`;


const SpaceGrid = styled.div`
    display: flex;
    flex-direction: row;
    height: 2em;
    justify-content: stretch;
    align-items: start;
    margin-top: 0.5em;
    width: 100%;
    
    @media (max-width: ${size.mobileM}) {
      flex-direction: column;
      justify-content: center;
      align-items: stretch;
      margin-top: 4em;
    }
`;

const SubscribeButton = styled.input`
    margin: 0 0 0 0.5em;
    font-size: 1em;
    padding: 0.3em 1.5em;
    cursor: pointer;
    background: black;
    color: white;
    border: none;
    height: calc(2em + 12px);
    flex-grow: 0;
    flex-shrink: 0;
    outline: none;
    
    &:hover {
        background: #555;
    }
    
    @media (max-width: ${size.mobileM}) {
        margin: 0.5em 0 3em 0;
    }
`;

const MailChimpForm = () => (
    <>
        <div id="mc_embed_signup">
            <Form
                action={NewsLetterHREF}
                method="post"
                id="mc-embedded-subscribe-form"
                name="mc-embedded-subscribe-form"
                target="_blank"
                noValidate
            >
                <div id="mc_embed_signup_scroll">
                    <Label htmlFor="mce-EMAIL">Sign Up Here for News</Label>
                    <SpaceGrid>
                        <AddressInput type="email"
                                   defaultValue=""
                                   name="EMAIL"
                                   id="mce-EMAIL"
                                   placeholder="email address"
                                   required />

                        <div style={{
                                 position: 'absolute',
                                 left: '-5000px'
                             }}
                             aria-hidden="true"
                        >
                            <input type="text"
                                   name="b_ec5fe633de77e4de48587fbf1_d805460756"
                                   tabIndex="-1"
                                   defaultValue="" />
                        </div>
                        <SubscribeButton type="submit"
                               value="Subscribe"
                               name="subscribe"
                               id="mc-embedded-subscribe" />
                    </SpaceGrid>
                </div>
            </Form>
        </div>
    </>
);

export default MailChimpForm;
