import React from 'react';
import styled from 'styled-components';

import bcLogo from '../images/bc_logo.png';
import cccLogo from '../images/ccc_logo.png';
import broadReachRecordsLogo from '../images/broad_reach_record_logo.png';
import vivienHollowayLogo from '../images/VOH_Logo_2017.png';
import hbfMasterLogo from '../images/hbf_master_logo.png';
import jubileeLogo from '../images/Jubilee-logo.png'
import lottery from '../images/lottery_logo.png';
import herneBayPier from '../images/herne_bay_pier.png';
import kccLogo from '../images/kcc_logo.png';

const sponsorList = [
    lottery,
    bcLogo,
    cccLogo,
    vivienHollowayLogo,
    broadReachRecordsLogo,
    hbfMasterLogo,
    jubileeLogo,
    herneBayPier,
    kccLogo
];

const makeLogos = (sponsorList, id) => sponsorList.map(
    logoSrc => <img src={logoSrc} alt="Sponsor Logo" key={`sponsor_${id}_${logoSrc}`} />
);

const NoBreak = styled.span`
  white-space: nowrap;
  
  @media (max-width: 600px) {
    white-space: normal;
  }
  
  @media (max-width: 480px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: stretch;
    width: 100%;
  }
`;

const Container = styled.div`
  text-align: center;
  @media (max-width: 480px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: stretch;
    width: 100%;
  }
`;

const Sponsors = () => (
    <Container>
        {makeLogos(sponsorList.slice(0, -3))}
        <NoBreak>
            {makeLogos(sponsorList.slice(-3))}
        </NoBreak>
    </Container>
);

export default Sponsors;
