import styled from 'styled-components';
import colors from '../constants/colors';

const AppContainer = styled.div`
    font-family: Arial, Helvetica, sans-serif;
    font-size: medium;
    
    p {
        letter-spacing: 0.02em;
        line-height: 1.3em
    }
    
    a {
        color: ${colors.logoBlue};
        text-decoration: none;
        transition: color 0.3s;
    }
    
    a:hover {
        color: ${colors.logoHoverBlue};
    }
`;

export default AppContainer;
