import React, {  useState, useCallback } from 'react';
import styled, { css } from 'styled-components';
import { PageContainer, AlignRight, InputButton } from './styles';
import { Heading2 } from '../components/Heading';
import { NewsLetterHREF } from '../components/MailChimpForm';
import colors from '../constants/colors';
import Progress from '../components/Progess';
import EmailService from '../services/Email';
import { size } from '../constants/responsive';
import CrowdPic from '../images/crowd_footer.jpg';
import DisplayImage from '../components/DisplayImage';
import { HideOnMobile } from '../components/Mobile';

const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

const Base = css`
    font-size: 1em;
    padding: 0.5em 1em;
    border: solid black;
    border-width: 0 0 1px 0;
    margin-right: 1em;
    display: inline-block;
    flex-grow: 1;
    flex-shrink: 0;
    outline: none;
    
    
    @media (max-width: ${size.mobileL}) {
      margin-bottom: 1em; 
    }
`;

const LineInput = styled.input`
    ${Base}
`;

const LineSelect = styled.select`
    ${Base};
    text-align: left;
    outline: none;
`;

const Message = styled.textarea`
    font-size: 1em;
    border: none;
    margin: 1em 0;
    padding: 1em;
    background: #EFEFEF;
    width: 100%;
    height: 15em;
    outline: none;
`;

const Row = styled.div`
    display: flex;
    justify-content: stretch;
    align-items: center; 
    margin-bottom: 1em;
    
    @media (max-width: ${size.mobileL}) {
      flex-direction: column;
      align-items: stretch;
    }
`;

const ErrorMessage = styled.div`
    display: inline-block;
    color: ${colors.error};
    margin-right: 1.5em;
`;


const defaultValues = () => ({
    userName : '',
    email: '',
    subject: '0',
    message: ''
});

const Contact = () => {
    const [values, setValues] = useState(defaultValues());
    const [error, setError] = useState(false);
    const [sending, setSending] = useState(false);
    const [result, setResult] = useState(null);

    const updateValues = useCallback(
        ({ target }) => setValues({ ...values, [target.name]: target.value }),
        [values, setValues]
    );

    const validate = () => values.userName
        && values.subject !== '0'
        && values.message
        && values.email.match(emailPattern);

    const resetForm = useCallback(
        () => {
            setValues(defaultValues());
            setError(false);
            setResult(null);
        },
        [setValues, setError, setResult]
    );

    const submitForm = useCallback(
        async event => {
            event.preventDefault();
            setError(!validate());
            if (validate()) {
                setSending(true);
                const success = await EmailService.sendEmail(
                    values.userName,
                    values.email,
                    values.subject,
                    values.message
                );
                setSending(false);
                setResult(
                    success
                        ? 'Email has been sent'
                        : 'Email failed to send, please try again later.'
                );
            }
        },
        [values, setError, setSending]
    );

    return (
        <PageContainer>
            <Heading2>Contact</Heading2>
            <p>
                To get the latest news, <a href={NewsLetterHREF} target="_blank">subscribe</a> to our newsletter, or
                get in touch with the festival team here by email:
            </p>
            <form onSubmit={submitForm}>
                <Row>
                    <LineInput type="text"
                               name="userName"
                               placeholder="Full Name"
                               value={values.userName}
                               onChange={updateValues} />

                    <LineInput type="text"
                               name="email"
                               placeholder="Email Address"
                               value={values.email}
                               onChange={updateValues} />
                </Row>

                <Row>
                    <label style={{ marginRight: '1em' }} htmlFor="subject">Subject</label>

                    <LineSelect name="subject"
                                value={values.subject}
                                onChange={updateValues}
                    >
                        <option value="0">Choose your subject</option>
                        <option value="General Enquiries">General Enquiries</option>
                        <option value="Musicians and Bands">Musicians and Bands</option>
                        <option value="Press / Media">Press / Media</option>
                        <option value="Sponsorship">Sponsorship</option>
                    </LineSelect>
                </Row>

                <label htmlFor="message">Message</label>

                <Message name="message"
                         value={values.message}
                         onChange={updateValues} />

                <AlignRight>
                    {error && <ErrorMessage>Please complete the form with a valid email.</ErrorMessage>}
                    {result && <span>{result}</span>}
                    <InputButton type="reset" value="Clear" onClick={resetForm} />
                    {
                        !result
                            && !sending
                            && <InputButton type="submit" value="Send" style={!validate() ? { opacity: '0.3' } : {}} />
                    }
                    { !result && sending && <Progress margin="0 2.1em">Sending...</Progress>}
                </AlignRight>
            </form>
            <HideOnMobile>
                <DisplayImage src={CrowdPic} alt="View out to sea" shadow style={{ marginTop: "2em"}} />
            </HideOnMobile>
        </PageContainer>
    );
};

export default Contact;
